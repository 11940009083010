<template>
  <div>
    <Description/>
  </div>
</template>

<script>
import Description from '@/components/template/Description.vue'

export default {
  components:{
    Description
  }
}
</script>

<style>

</style>